@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gt America';
  src: url('./assets/fonts/GT-America-Condensed-Regular-Italic.otf');
  font-style: italic;
}

@font-face {
  font-family: 'Gt America';
  src: url('./assets/fonts/GT-America-Condensed-Regular.otf');
}

@font-face {
  font-family: 'Gt America';
  src: url('./assets/fonts/GT-America-Condensed-Bold-Italic.otf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gt America';
  src: url('./assets/fonts/GT-America-Condensed-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Gt America';
  src: url('./assets/fonts/GT-America-Condensed-Medium-Italic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gt America';
  src: url('./assets/fonts/GT-America-Condensed-Medium.otf');
  font-weight: 500;
}


@font-face {
  font-family: 'Itf Editor';
  src: url('./assets/fonts/ITF Editor Condensed Regular.otf');
}

@font-face {
  font-family: 'Itf Editor';
  src: url('./assets/fonts/ITF Editor Condensed Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Itf Editor';
  src: url('./assets/fonts/ITF Editor Condensed Extrabold.otf');
  font-weight: bolder;
}

@font-face {
  font-family: 'Itf Editor';
  src: url('./assets/fonts/ITF Editor Condensed Light.otf');
  font-weight: lighter;
}

@font-face {
  font-family: 'Itf Editor';
  src: url('./assets/fonts/ITF Editor Condensed Medium.otf');
  font-weight: 500;
}

.font-gt-america {
  font-family: 'Gt America';
}

.font-itf-editor {
  font-family: 'Itf Editor';
}

html {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
  min-height: 100%;
  color: #2f2f2f;
}

/* header */

header {
  margin: 0.5em 0 1.5em 0;
  text-align: center;
}

.layout {
  padding: 1rem 1rem;
  max-width: 1400px;
  min-width: 1260px;
  margin: auto;
}

.full-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-logo {
  margin: 0;
  display: none;
  font-size: 1.7em;
  margin-right: 0.5em;
}

.title {
  margin: 0;
}

.subtitle {
  margin: 0;
  color: gray;
}

/* app */

.app {
  max-width: 1300px;
}

/* select and last update */

.select-wrapper {
  margin-bottom: 2em;
}

.select-product-wrapper {
  margin-bottom: 1em;
}

.select-product-title {
  margin: 0 0 0.4em 0.1em;
}

.select-product {
  border: #2f2f2f solid 1px;
  border-radius: 12px;
  width: 100%;
}

.select-shipping-title {
  margin: 0 0 0.4em 0.1em;
}

.select-shipping {
  border: #2f2f2f solid 1px;
  border-radius: 12px;
  width: 100%;
}

.last-update {
  display: none;
  margin: 0.5em 0 0 auto;
  flex-direction: column;
  justify-content: center;
  color: gray;
}

.last-update-text,
.last-update-time {
  margin: 0;
}

.show-results-from {
  font-size: 14px;
  margin: 0 0 2em 0.1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.8em;
  row-gap: 0.3em;
}

.show-results-from-text {
  margin: 0;
  font-weight: 100;
  white-space: nowrap;
}

.show-results-from-link {
  margin: 0;
  color: #36D7CC;
  text-decoration: none;
  font-weight: 300;

  display: flex;
  align-items: center;
  gap: 0.2em;
}

/* table */

/* footer */

footer {
  display: none;
}

@media screen and (min-width: 500px) {
  .search-logo {
    display: unset;
  }

  .app {
    padding-bottom: 2.5em;
  }

  footer {
    display: unset;
    width: 100%;
    height: 30px;
    background-color: #fafafa;
    position: absolute;
    bottom: 0px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: lightgray solid 1px;
  }
}

@media screen and (min-width: 600px) {
  header {
    margin: 2em 0 3em 0;
  }

  .app {
    width: 90%;
    margin: auto;
  }
  .select-wrapper {
    display: flex;
  }

  .select-product-wrapper {
    width: 250px;
    margin-right: 4em;
  }

  .select-shipping-wrapper {
    width: 150px;
  }
}

@media screen and (min-width: 800px) {
  .last-update {
    display: flex;
  }
}

.ant-table-content > table {
  border-left: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
}

.ant-tabs-tab {
  padding: 8px 20px 6px !important;
  color: #0000008F !important;
  font-family: 'Gt America' !important;
}

.ant-tabs-tab-active {
  border-bottom: 1px solid #e5e7eb !important;
}

.ant-tabs-tab-active > div {
  color: #000 !important;
}

.ant-tabs-nav {
  margin: 0px !important;
}

.ant-table-cell > a {
  color: #36D7CC !important;
}

.ant-tag {
  color: white !important;
  border: none !important;
  border-radius: 10px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.ant-tag-purple {
  background-color: #9454A3 !important;
}

.ant-tag-blue {
  background-color: #6060bc !important;
}

.ant-tag-magenta {
  background-color: #36D7CC !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ant-pagination-item-active, .ant-pagination-item:hover {
  border: none !important;
  background-color: #EEE6DA !important;
}

.ant-pagination-item-active > a {
  color: #000 !important;
}